/* eslint-disable react/prop-types */
import React from 'react'
import Link from 'gatsby-link'
import { graphql, useStaticQuery } from 'gatsby'
const VerticalMenu = () => {
    const data = useStaticQuery(graphql`
    query BlogQuryVertical {
      allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              path
              date(formatString: "MMMM DD, YYYY")
              tags
              excerpt
            }
          }
        }
      }
    }
  `)
    const {edges: posts} = data.allMarkdownRemark
    return (
        <div className="rightPart">
            <h2>Recent Posts</h2>
            <ul>
                {posts.slice(0, 5).map(({node: post,key}) => {
                    const {frontmatter} = post
                    return (
                        <li key={key}><Link key={key} to ={frontmatter.path}>{frontmatter.title}</Link></li>
                    )
                })}
            </ul>
        </div>
    )

}
export default VerticalMenu