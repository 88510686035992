/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Pagination from '../components/pagination'
import VerticalMenu from '../components/blogComponents/verticalMenu'
import blogImage from '../images/blogImg.svg'

const Blog = ({data}) => {
    const {edges: posts} = data.allMarkdownRemark
    const totalItems = data.allMarkdownRemark.totalCount
    const [ currentPage, setCurrentPage ] = useState(1)
    const itemsPerPage = 2
    const indexOfLastItem = currentPage *itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    return (
        <Layout>
            <Seo title="Blog" description="BricoBrowser blog.. find all latest news about new incognito browser Brico Browser" />
            <div className="bg-bgColor">
                <div className=" max-w-screen-xl w-4/5 md:w-full m-auto md:flex justify-center items-center mt-2">
                    <p className="mb-4 text-left font-bold text-xl sm:text-3xl">Our Blog</p>
                    <div className="back animate-fade-in-up w-3/5 md:w-1/4 m-auto md:py-4 flex">
                        <img className="" src={blogImage}/>
                    </div>
                </div>
            </div>
            <section className="comment">
                <div className="leftPart">
                    {posts.slice(indexOfFirstItem, indexOfLastItem).map(({node: post,key}) => (
                        <React.Fragment key={key}>
                            <div className="firstComment">
                                <h3><Link to ={post.frontmatter.path}>{post.frontmatter.title}</Link></h3>
                                <p className="little">{post.frontmatter.date}</p>
                                <p><i>{post.frontmatter.excerpt} </i></p>
                                <button><Link className="no-underline text-textColor" to={post.frontmatter.path}>Read more</Link></button>
                            </div>
                            <hr/>
                        </React.Fragment>
                    ))}
                    <Pagination itemsPerPage={itemsPerPage} totalItems={totalItems} paginate={paginate} currentPage={currentPage} />
                   
                </div>
                <div className="vertical"> 
                </div>
                <VerticalMenu/>
            </section>
        </Layout>

    )

}
export const query = graphql`
query BlogQuery {
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            path
            date(formatString: "MMMM DD, YYYY")
            tags
            excerpt
          }
        }
      }
    }
  }
`
export default Blog